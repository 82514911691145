<template>
  <div>
    <loading-dialog :loading="$apollo.queries.adminAccount.loading">
      <v-card flat class="pa-2">
        <v-row no-gutters>
          <v-col cols="4">
            <v-card class="mb-3 input-box" flat>
              <v-card-text>
                <label class="subheading">Tipo:</label>
                <mf-text-input v-model="type" disabled />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="mb-3 input-box" flat>
              <v-card-text>
                <label class="subheading">Driver:</label>
                <mf-text-input v-model="driver" disabled />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card-text class="ml-4">
              <label class="subheading">Retry:</label>
              <v-switch v-model="retry" :disabled="!editable" value class="mr-auto" />
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="ml-2">
          <btn-card-actions class="pa-0" :editable.sync="editable" @save="changeRetry" @cancel="cancelAndRestoreData" />
          <v-btn width="260" class="mt-2" color="primary" outlined @click="showParams = true"><v-icon class="mr-1">mdi-console-line</v-icon>Parâmetros</v-btn>
        </v-row>

        <v-expansion-panels class="mt-3" focusable hover>
          <v-expansion-panel v-for="(server, index) of servers" :key="index" class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">Server {{ getSafe(() => `${server.host}:${server.port}`) }}</span>
            </v-expansion-panel-header>
            <hotsite-servers :server="server" :index="index" :account-id="accountId" @refresh="refresh" />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-row no-gutters class="ma-2">
        <v-btn color="primary" dark outlined block @click="showAddServer = true"><v-icon small class="mr-2">mdi-server</v-icon> Adicionar servidor </v-btn>
      </v-row>

      <skaar-server-modal :server="newServer" :dialog="showAddServer" @close="cancelAndRestoreData" @save="addServer" />
      <skaar-params :id="accountId" :dialog="showParams" :parameters="parameters" @close="cancelAndRestoreData" />
    </loading-dialog>

    <v-expansion-panels v-model="panel" focusable multiple>
      <v-expansion-panel class="mb-2">
        <!-- <v-expansion-panel-header>
          <span>Configurações gerais</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card class="mb-2" flat>
            <hotsite-general-settings />
          </v-card>
        </v-expansion-panel-content> -->
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>
          <span>Termos</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card class="mb-2" flat>
            <terms />
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { QUERY_GET_ACCOUNT, MUTATION_EDIT_RETRY_SKAAR, MUTATION_ADD_SKAAR_SERVER } from '@/modules/accounts/graphql'

export default {
  name: 'Hotsite',
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog'),
    SkaarParams: () => import('@/modules/accounts/partials/SkaarParams'),
    SkaarServerModal: () => import('@/modules/accounts/partials/SkaarServerModal'),
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    HotsiteServers: () => import('@/modules/accounts/components/hotsite/HotsiteServers'),
    Terms: () => import('@/modules/accounts/components/hotsite/Terms')
    // HotsiteGeneralSettings: () => import('@/modules/accounts/components/hotsite/HotsiteGeneralSettings')
  },
  data: () => ({
    accountId: '',
    editable: false,
    showAddServer: false,
    showParams: false,
    newServer: {
      host: '',
      port: '',
      user: '',
      password: '',
      database_name: ''
    },
    panel: [0],
    parameters: {},
    driver: '',
    servers: [],
    type: '',
    retry: null
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.parameters = adminAccount?.erp_sync.erp_configuration.parameters
        this.servers = adminAccount?.erp_sync.erp_configuration.servers
        this.driver = adminAccount?.erp_sync.erp_configuration.driver
        this.type = adminAccount?.erp_sync.type.toUpperCase()
        this.retry = adminAccount?.erp_sync?.retry
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async changeRetry() {
      const confirmation = await this.$confirm({
        title: 'Salvar ações',
        message: 'Deseja realizar as alterações?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_RETRY_SKAAR,
          variables: {
            id: this.accountId,
            value: this.retry
          }
        })
        this.cancelAndRestoreData()
        await this.refresh()
        this.$snackbar({ message: 'Configurações do Hotsite atualizadas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar configurações do Hotsite', snackbarColor: '#F44336' })
      }
    },
    async addServer() {
      const confirmation = await this.$confirm({
        title: 'Criar servidor',
        message: 'Deseja criar o servidor?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_ADD_SKAAR_SERVER,
          variables: {
            id: this.accountId,
            host: this.newServer.host,
            port: this.newServer.port,
            user: this.newServer.user,
            password: this.newServer.password,
            database_name: this.newServer.database_name
          }
        })
        this.showAddServer = false
        await this.refresh()
        this.$snackbar({ message: 'Servidor adicionado', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao adicionar servidor', snackbarColor: '#F44336' })
      }
    },
    cancelAndRestoreData() {
      this.editable = false
      this.showParams = false
      this.showAddServer = false
    },
    async refresh() {
      return this.$apollo.queries.adminAccount.refetch()
    }
  }
}
</script>
